import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"

// COMPONENTS
import SmoothieCard from "../components/SmoothieCard"

const Home = () => {
  const [fetcherror, setFetchError] = useState(null)
  const [smoothies, setSmoothies] = useState(null)

  useEffect(() => {
    const fetchSmoothies = async () => {
      const { data, error } = await supabase
        .from('smoothies')
        .select()
      
      if (error) {
        setFetchError("404 No Smoothies Found")
        setSmoothies(null)
        console.log(error)
      }
      if (data) {
        setSmoothies(data)
        setFetchError(null)
      }
    }

    fetchSmoothies()

    
  }, [])

  return (
    <div className="page home">
      {fetcherror && (<p>{fetcherror}</p>)}
      {smoothies && (
        <div className="smoothies">
          {/* order-by buttons */}
          <div className="smoothie-grid">
            {smoothies.map(smoothie => (
              <SmoothieCard key={smoothie.id} smoothie={smoothie} />
            ))}
          </div>
          
        </div>
      )}
    </div>
  )
}

export default Home